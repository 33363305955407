<template>
  <div class="home">
    <v-card flat :color="background">
      <v-img
        height="750px"
        :src="require('@/assets/background-content.png')"
      >
        <v-container>
          <HomeNavBar />

          <v-card-text>
            <v-row class="mt-10" :class="{ 'mt-n5': $vuetify.breakpoint.mdAndDown }">
              <v-col cols="12" lg="4">
                <CalcComponent />
              </v-col>
              <v-col>
                <div class="text-center mt-15">
                  <span class="font-weight-bold text-h2">Score Higher and Submit <br> Your Paper  <br>
                  within the Deadline</span>
                </div>

                <div class="text-center mt-6">
                  <span class="normal-text">
                    Our experts are ready to help you any time for you to achieve the best <br>
                      grades and submit your work in time
                  </span>
                </div>

                <div class="text-center mt-14">
                  <v-btn rounded class="px-11" :color="essayOrange" to="/order/draft" >
                    Order with us
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
      </v-img>


    </v-card>

    <div class="text-center mt-10">
      <span class="text-h4">How it works</span>

      <div class="mt-4">
        <span class="normal-text"> Get your work done in 3 simple steps</span>
      </div>
    </div>

  <v-container>
    <div class="mt-10 mx-auto d-flex">
      <v-card flat color="transparent" class="">
        <v-img
          height="61px"
          width="61px"
          :src="require('@/assets/img/svg/one.svg')"
          class="mx-auto"
        ></v-img>

        <v-card-title> 
          <v-spacer></v-spacer>
          <span>
          Place order
          </span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text class="text-center">
          <span>
            Slate helps you see how 
            many more days you need 
            to work to reach your 
            financial goal.
          </span>
        </v-card-text>

      </v-card>

      <v-card flat color="transparent" class="">
        <v-img
          height="61px"
          width="61px"
          :src="require('@/assets/img/svg/one.svg')"
          class="mx-auto"
        ></v-img>

        <v-card-title> 
          <v-spacer></v-spacer>
          <span>
          Place order
          </span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text class="text-center">
          <span>
            Slate helps you see how 
            many more days you need 
            to work to reach your 
            financial goal.
          </span>
        </v-card-text>

      </v-card>

      <v-card flat color="transparent" class="">
        <v-img
          height="61px"
          width="61px"
          :src="require('@/assets/img/svg/one.svg')"
          class="mx-auto"
        ></v-img>

        <v-card-title> 
          <v-spacer></v-spacer>
          <span>
          Place order
          </span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text class="text-center">
          <span>
            Slate helps you see how 
            many more days you need 
            to work to reach your 
            financial goal.
          </span>
        </v-card-text>

      </v-card>
    </div>

    <div class="mx-auto mt-10 text-center">
      <h2> FEATURES</h2>
      <span class="mt-5 text--disabled">We are dedicated to give you the best</span>
    </div>

    <div class="mt-5 mx-auto d-flex">
      <div class="" v-if="$vuetify.breakpoint.mdAndUp">
        <v-img
        :src="require('@/assets/img/svg/writers.svg')"
        > 
        </v-img>
      </div>
      <div class="mt-15">
        <div class="mt-15"></div>
        <v-card flat color="transparent">
          <v-card-title>
            <v-avatar>
              <v-icon>mdi-check</v-icon>
            </v-avatar>
            <span>24/7 customer support</span>
          </v-card-title>
          <v-card-text class="px-10">
            <span>
              When you add work to your 
              Slate calendar we automatically 
              calculate useful insights 
            </span>
          </v-card-text>
        </v-card>

        <v-card flat color="transparent">
          <v-card-title>
            <v-avatar>
              <v-icon>mdi-check</v-icon>
            </v-avatar>
            <span>Highly Experienced Writers</span>
          </v-card-title>
          <v-card-text class="px-10">
            <span>
              Vetted and highly experienced writers 
            </span>
          </v-card-text>
        </v-card>

        <v-card flat color="transparent">
          <v-card-title>
            <v-avatar>
              <v-icon>mdi-check</v-icon>
            </v-avatar>
            <span>We value your privacy</span>
          </v-card-title>
          <v-card-text class="px-10">
            <span>
              We do not collect any of your personal data and information
              we understand that your privacy is key.
            </span>
          </v-card-text>
        </v-card>
      </div>

    </div>


    <!-- <div class="mt-5 d-flex justify-center"> -->
    <v-row justify="center" class="mt-5">
      <v-col class="d-flex justify-end">
        <v-card 
          height="473px"
          flat 
          color="transparent"
        >
          <v-card-title class="">
            <span>
            Fastest way to <br> get  your paper done
            </span>
          </v-card-title>

          <v-card-text>
            <span>Order with us to get best grades and <br> get your paper in time</span>
          </v-card-text>

          <v-card-actions>
            <v-btn rounded class="px-11 mt-5" :color="essayOrange" to="/order/draft">
              Order with us
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      
      <v-col>
        <div class="">
          <v-img 
          :src="require('@/assets/img/svg/screen-background.svg')"
          width="593px"
          class="mx-auto"
          >
          </v-img>
          <div class="barrel"></div>
        </div>
      </v-col>
    </v-row> 
    <!-- </div> -->

    <div class="mt-10 text-center">
      <span class="text-h4"> Testimonials</span>

      <v-row justify="center">
        <v-col></v-col>
        <v-col>
          <v-card color="#181818" class="rounded-lg mt-5" width="430px">
            <div class="d-flex pa-4"> 
              <v-avatar color="grey">
                <v-icon>mdi-account</v-icon>
              </v-avatar>
              <div class="ml-3">
                <v-list-item-title> Claire Bell</v-list-item-title>
                <v-list-item-subtitle class="ml-n3">Designer </v-list-item-subtitle>
              </div>
            </div>

            <v-card-text>
              <span>
                Slate helps you see how many more days 
                you need to work to reach your financial 
                goal for the month and year. Slate helps 
                you see how many more days 
                you need to work to reach your financial 
                goal for the month and year.your financial 
                goal for the month and year.
              </span>
            </v-card-text>

          </v-card>
        </v-col>

        <v-col>
          <v-card color="#181818" class="rounded-lg mt-5" width="430px">
            <div class="d-flex pa-4"> 
              <v-avatar color="grey">
                <v-icon>mdi-account</v-icon>
              </v-avatar>
              <div class="ml-3">
                <v-list-item-title> Francisco Lane</v-list-item-title>
                <v-list-item-subtitle class="ml-n5">Designer </v-list-item-subtitle>
              </div>
            </div>

            <v-card-text>
              <span>
                Slate helps you see how many more days 
                you need to work to reach your financial 
                goal for the month and year. Slate helps 
                you see how many more days 
                you need to work to reach your financial 
                goal for the month and year.your financial 
                goal for the month and year.
              </span>
            </v-card-text>

          </v-card>
        </v-col>
        <v-col></v-col>
      </v-row>

      <v-row justify="center">
        <v-col></v-col>
        <v-col>
          <v-card color="#181818" class="rounded-lg mt-5" width="430px">
            <div class="d-flex pa-4"> 
              <v-avatar color="grey">
                <v-icon>mdi-account</v-icon>
              </v-avatar>
              <div class="ml-3">
                <v-list-item-title> Claire Bell</v-list-item-title>
                <v-list-item-subtitle class="ml-n3">Designer </v-list-item-subtitle>
              </div>
            </div>

            <v-card-text>
              <span>
                Slate helps you see how many more days 
                you need to work to reach your financial 
                goal for the month and year. Slate helps 
                you see how many more days 
                you need to work to reach your financial 
                goal for the month and year.your financial 
                goal for the month and year.
              </span>
            </v-card-text>

          </v-card>
        </v-col>

        <v-col>
          <v-card color="#181818" class="rounded-lg mt-5" width="430px">
            <div class="d-flex pa-4"> 
              <v-avatar color="grey">
                <v-icon>mdi-account</v-icon>
              </v-avatar>
              <div class="ml-3">
                <v-list-item-title> Francisco Lane</v-list-item-title>
                <v-list-item-subtitle class="ml-n5">Designer </v-list-item-subtitle>
              </div>
            </div>

            <v-card-text>
              <span>
                Slate helps you see how many more days 
                you need to work to reach your financial 
                goal for the month and year. Slate helps 
                you see how many more days 
                you need to work to reach your financial 
                goal for the month and year.your financial 
                goal for the month and year.
              </span>
            </v-card-text>

          </v-card>
        </v-col>
        <v-col></v-col>
      </v-row>
    </div>

    <div class="mt-15 pl-10">
      <div class="mt-15">
        <span class="text-h5"> Let us help you with all  your homework needs </span>
      </div>
      <v-btn class="px-11 mt-5" :color="essayOrange" to="/order/draft">
        Order with us now
      </v-btn>
    </div>

    <Footer class="mt-10" />
  </v-container>
    
    
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import colorMxn from '@/mixins/colorMxn';

export default {
  name: 'Home',
  components: {
    HomeNavBar: ()  => import('@/components/HomeNavbar'),
    Footer: () => import('@/components/Footer'),
    CalcComponent: () => import('./HomeComponents/CalcComponent')
  },
  mixins: [colorMxn],
  data() {
    return {
      assignItems: [],
      assignment_type: 'Essay (any type)',
      levelItems: [],
      level: 'College',
      pages: 1,
      amount: 11.4,
      service: 'Writing',
    }
  },
  computed: {
    ...mapGetters(['getDirectory', 'getAmount']),
  },
  watch: {
    service(oldval, newVal) {
      if (oldval !== newVal) {
        this.calcPrice()
      }
    },
    getAmount(val) {
      this.amount = val;
    },
    pages(oldval, newVal) {
      if (oldval !== newVal) {
        this.calcPrice()
      }
    }
  },
  mounted() {
    this.assignItems = this.getDirectory ? this.getDirectory.types : this.assignItems;
    this.levelItems = this.getDirectory ? this.getDirectory.levels : this.levelItems;
    this.setOrderObject(null);
  },
  methods: {
    ...mapMutations(['setAmount', 'setOrderObject']),
    increment() {
      this.pages = this.pages + 1;
      this.calcPrice();
    },
    decrement() {
      this.pages = this.pages - 1;
      this.calcPrice();
    },
    calcPrice() {
      const writing = 10.4;
      const editing = 10.4;
      const rewriting = 6.98;
      const proofreading = 4.98;

      let amount = 10.4;
      switch (this.service) {
        case 'Writing': 
          amount = parseInt(this.pages) * writing; 
          break;
        case 'Rewriting': 
          amount = parseInt(this.pages) * rewriting; 
          break;
        case 'Editing': 
          amount = parseInt(this.pages) * editing; 
          break;
        case 'Proofreading': 
          amount = parseInt(this.pages) * proofreading; 
          break;
        default:
          break;
      }
      this.setAmount(amount);
    }
  }
}
</script>

<style>
.normal-text {
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.2px;
}

.calc-services {
  height: 58px;
  background: #C4C4C4;
  border-radius: 10px;
}

.calc-btn-text {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #675C5C;
}

.barrel {
  width: 726px;
  height: 12.89px;
  border-radius: 4px;
  background: #D8D8D8
}
</style>
